import '@fortawesome/fontawesome-free/css/all.css'
// import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { ErrorBoundary } from '@sentry/nextjs'
// import '@fortawesome/fontawesome-free/scss/regular.scss'
// import '@fortawesome/fontawesome-free/scss/solid.scss'
import moment from 'moment'
import 'moment/locale/it'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { Suspense } from 'react'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import { SWRConfig } from 'swr'
import 'tailwindcss/tailwind.css'

import QueryProvider from '../QueryProvider'
import { basicFetch } from '../api'
import '../base.scss'
import LoadingScreen from '../components/LoadingScreen'
import { isDev, UMAMI_WEBSITE_ID } from '../config'
import AuthProvider from '../containers/AuthProvider'
import NotificationsContainer from '../containers/Notifications'
import PopupProvider from '../containers/PopupProvider'
import { BreadcrumbsContext } from '../containers/Breadcrumbs'

// if (environment !== 'development') disableReactDevTools()

moment.locale('it')

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      {UMAMI_WEBSITE_ID && (
        <Head>
          <script src="/umami/script.js" data-website-id={UMAMI_WEBSITE_ID} async defer />
        </Head>
      )}
      <ErrorBoundary showDialog={isDev}>
        <Suspense
          fallback={
            <div className="my-6 px-6 max-w-view mx-auto">
              <LoadingScreen />
            </div>
          }
        >
          <div id="popup-portal"></div>
          <RecoilRoot>
            <RecoilNexus />
            <AuthProvider>
              <SWRConfig value={{ fetcher: basicFetch, suspense: true }}>
                <QueryProvider>
                  <PopupProvider>
                    <BreadcrumbsContext>
                      <>
                        <NotificationsContainer />
                        <Component {...pageProps} />
                      </>
                    </BreadcrumbsContext>
                  </PopupProvider>
                </QueryProvider>
              </SWRConfig>
            </AuthProvider>
          </RecoilRoot>
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
