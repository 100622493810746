import classNames from 'classnames'
import Link from 'next/link'
import { createContext, useContext, useEffect, useState } from 'react'

export type Crumb = {
  label: string
  href: string
}
const HomeCrumb: Crumb = { label: 'Home', href: '/' }
type BreadcrumbsContext = {
  setCrumbs: (crumbs: Crumb[]) => void
  crumbs: Crumb[]
}

const breadcrumbsContext = createContext<BreadcrumbsContext>({
  setCrumbs: () => {
    throw new Error('setCrumbs not implemented')
  },
  crumbs: [HomeCrumb]
})

export function BreadcrumbsContext({ children }: { children: React.ReactNode }) {
  const [crumbs, setCrumbs] = useState<Crumb[]>([HomeCrumb])
  return <breadcrumbsContext.Provider value={{ setCrumbs, crumbs }}>{children}</breadcrumbsContext.Provider>
}

export function SetBreadcrumbs({ crumbs }: { crumbs: Crumb[] }) {
  const { setCrumbs } = useContext(breadcrumbsContext)
  useEffect(() => {
    setCrumbs(crumbs)

    return () => {
      setCrumbs([HomeCrumb])
    }
  }, [crumbs, setCrumbs])
  return null
}

export function Breadcrumbs({ className }: { className?: string }) {
  const { crumbs } = useContext(breadcrumbsContext)

  return (
    <div className={classNames('text-[0.8em] font-medium', className)}>
      {crumbs.map((crumb, i) => (
        <span key={crumb.href}>
          {i === crumbs.length - 1 ? (
            <span className="text-gray-400">{crumb.label}</span>
          ) : (
            <>
              <Link href={crumb.href}>
                <a
                  className={classNames(
                    'transition-all text-gray-400 hover:text-primary-dark active:text-primary-darker'
                  )}
                >
                  {crumb.label}
                </a>
              </Link>
              <i className="fas fa-chevron-right text-gray-300 mx-2 text-[0.7em]" />
            </>
          )}
        </span>
      ))}
    </div>
  )
}
