import { useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'

type NotificationOptions = {
  // Some styles are not yet implemented
  style: 'success' | 'error' | 'info' | 'warning'
  // Timeout in ms
  timeout: number | null
  // Show close button
  closeable: boolean
  // For optional cleanup
  onClose?: () => void
}

type NotificationProps = {
  title: string
  icon: string | null
  description?: string
  onConfirm?: {
    label: string
    onClick: () => void
  } | null
  onCancel?: {
    label: string
    onClick: () => void
  } | null
}

type NotificationMeta = {
  id: number
  timeoutHandle1: number | null
  timeoutHandle2: number | null
  view: boolean
  isLoading: boolean
}

export type Notification = NotificationProps & NotificationOptions & NotificationMeta

export type NotificationsState = {
  notifications: Notification[]
  defaultOptions: NotificationOptions
}

const notificationsAtom = atom<NotificationsState>({
  key: 'notifications',
  default: {
    notifications: [
      // {
      //   id: 0,
      //   icon: null,
      //   title: 'Ciao',
      //   description: 'Come stai?',
      //   style: 'info',
      //   onConfirm: null,
      //   onCancel: null,
      //   timeout: null,
      //   timeoutHandle1: null,
      //   timeoutHandle2: null,
      //   view: true,
      //   isLoading: false,
      //   closeable: true
      // },
      // {
      //   id: 1,
      //   title: 'Errore',
      //   icon: 'fas fa-exclamation-triangle',
      //   description: 'Errore!',
      //   style: 'error',
      //   onConfirm: null,
      //   onCancel: null,
      //   timeout: null,
      //   timeoutHandle1: null,
      //   timeoutHandle2: null,
      //   view: true,
      //   isLoading: false,
      //   closeable: true
      // }
    ],
    defaultOptions: {
      style: 'info',
      timeout: 3000,
      closeable: true
    }
  }
})

export default notificationsAtom

const generateId = () => Math.round(Math.random() * Number.MAX_SAFE_INTEGER)

const PRE_TIMEOUT = 150

type SetState = (updater: (state: NotificationsState) => NotificationsState) => void

export const deleteNotification = (setState: SetState) => (id: number) => {
  setState(state => {
    const index = state.notifications.findIndex(n => n.id === id)
    if (index === -1) return state

    const notifications = [...state.notifications]
    const notification = { ...notifications[index] }
    notifications[index] = notification

    if (notification.timeoutHandle1 !== null) {
      window.clearTimeout(notification.timeoutHandle1)
      notification.timeoutHandle1 = null
    }
    if (notification.timeoutHandle2 !== null) {
      window.clearTimeout(notification.timeoutHandle2)
      notification.timeoutHandle2 = null
    }
    notification.view = false
    notification.timeoutHandle2 = window.setTimeout(() => {
      setState(state => {
        const index = state.notifications.findIndex(n => n.id === id)
        if (index === -1) return state

        const notifications = [...state.notifications]
        notifications.splice(index, 1)

        return {
          ...state,
          notifications
        }
      })
    }, PRE_TIMEOUT)

    return {
      ...state,
      notifications
    }
  })
}

export const createNotification =
  (setState: SetState) => (notification: NotificationProps & Partial<NotificationOptions> & { id?: number }) => {
    const id = notification.id ?? generateId()

    setState(state => {
      // Prevent duplicate notifications with the same id
      if (state.notifications.some(n => n.id === id)) return state

      const fullNotification: Notification = {
        ...state.defaultOptions,
        ...notification,
        id,
        timeoutHandle1: null,
        timeoutHandle2: null,
        view: true,
        isLoading: false
      }

      if (fullNotification.timeout !== null)
        fullNotification.timeoutHandle1 = window.setTimeout(() => {
          deleteNotification(setState)(id)
        }, fullNotification.timeout)

      return {
        ...state,
        notifications: [...state.notifications, fullNotification]
      }
    })

    return id
  }

const updateDefaultOptions = (setState: SetState) => (options: Partial<NotificationOptions>) => {
  setState(state => ({
    ...state,
    defaultOptions: {
      ...state.defaultOptions,
      ...options
    }
  }))
}

export function useNotifications() {
  const [state, setState] = useRecoilState(notificationsAtom)

  return {
    ...state,
    createNotification: useMemo(() => createNotification(setState), [setState]),
    deleteNotification: useMemo(() => deleteNotification(setState), [setState]),
    updateDefaultOptions: useMemo(() => updateDefaultOptions(setState), [setState])
  }
}
