import { PopupProvider as BasePopupProvider } from 'use-open-popup'
import 'use-open-popup/dist/style.css'

import styles from './PopupContainer.module.scss'

export default function PopupProvider({ children }: { children: JSX.Element }) {
  return (
    <BasePopupProvider
      onOpen={() => {
        if (window === undefined) return

        document.body.style.overflow = 'hidden'
        if (document.body.scrollHeight > window.innerHeight) document.body.style.marginRight = '1rem'
      }}
      onClose={() => {
        document.body.style.overflow = ''
        document.body.style.marginRight = ''
      }}
      containerClassName={styles.PopupContainer}
    >
      {children}
    </BasePopupProvider>
  )
}
