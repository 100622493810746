import { INVALID_TOKEN_ERR_PREFIX } from '@apb/shared/const'
import { getRecoil, setRecoil } from 'recoil-nexus'

import notificationsAtom, { createNotification as createNotificationFactory } from '../atoms/notifications'
import { baseApiUrl } from '../config'
import { clearToken, getToken } from '../services/auth'

const createNotification = createNotificationFactory(updater => {
  const notifications = getRecoil(notificationsAtom)
  const newState = updater(notifications)
  setRecoil(notificationsAtom, newState)
})

export type ApiError = {
  statusCode: number
  message: string
  code?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function processResponse(res: Response): Promise<any> {
  if (!res.ok) {
    // TODO: review error shape
    const errData: ApiError = await res.json()

    if (errData.statusCode === 401) {
      // Token is expired or invalid
      if (errData.message.startsWith(INVALID_TOKEN_ERR_PREFIX)) {
        clearToken()
        window.location.pathname = '/login'
      }

      createNotification({
        title: 'Errore: operazione non autorizzata',
        icon: 'fas fa-exclamation-triangle',
        description: 'Non hai i permessi per eseguire questa operazione.',
        style: 'error'
      })
    }

    throw errData
  }

  if (res.headers.get('Content-Type')?.startsWith('application/json')) return await res.json()

  if (res.headers.get('Content-Type')?.startsWith('image/')) return await res.arrayBuffer()

  return await res.text()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function basicFetch(url: string, method: 'GET' | 'POST' | 'DELETE' = 'GET'): Promise<any> {
  const token = getToken()

  const res = await fetch(baseApiUrl + url, {
    method,
    credentials: 'include',
    headers: token ? { Authorization: `bearer ${token}` } : {}
  })

  return await processResponse(res)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function sendFetch<D>(url: string, data: D): Promise<any> {
  const token = getToken()

  const res = await fetch(baseApiUrl + url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `bearer ${token}` } : {})
    },
    body: JSON.stringify(data)
  })

  return await processResponse(res)
}
