import { ReactNode, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import authAtom from '../atoms/auth'
import { getToken, parseToken } from '../services/auth'
import { trpcClient } from '../trpc'

export type AuthProviderProps = {
  children: ReactNode
}
export default function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = useRecoilState(authAtom)

  useEffect(() => {
    const token = getToken()
    const jwt = token !== null ? parseToken(token) : null
    if (jwt) {
      trpcClient.utenti.get
        .query({ utenteId: jwt.id })
        .then(user => {
          setAuthState({ user: jwt, role: user.role, path: user.path, state: 'loggedIn' })
        })
        .catch(() => {
          setAuthState({ state: 'loggedOut' })
        })
    } else setAuthState({ state: 'loggedOut' })
  }, [setAuthState])

  if (authState === undefined) return null

  return <>{children}</>
}
