import { useMemo } from 'react'
import classNames from 'classnames'

import { useNotifications, Notification } from '../atoms/notifications'
import Spinner from '../components/Spinner'

function getStyleColor(style: Notification['style']) {
  switch (style) {
    case 'error':
      return 'text-red-600'
    case 'success':
      return 'text-green-600'
    case 'warning':
      return 'text-yellow-600'
    default:
      return 'text-black'
  }
}

function Notification({ notification, onClose }: { notification: Notification; onClose: () => void }) {
  const color = useMemo(() => getStyleColor(notification.style), [notification.style])

  return (
    <div
      className={classNames(
        'rounded-xl shadow-card p-3 bg-white pt-2 max-w-[16rem] animate-toast-rest mt-3',
        notification.view && 'animate-toast'
      )}
    >
      {notification.closeable && (
        <button className="btn-neutral-icon-sm absolute top-1 right-1" onClick={onClose}>
          <i className="fas fa-times" />
        </button>
      )}
      <h4 className={classNames('mb-0', color)} style={{ lineHeight: '1.4' }}>
        {notification.icon !== null && (
          <i className={classNames(notification.icon, 'text-sm mr-1 align-[0.05em]', color)} />
        )}
        {notification.title}
      </h4>
      {notification.description !== undefined && <p>{notification.description}</p>}
      {(notification.onConfirm || notification.onCancel) && (
        <div className="grid gap-3 grid-cols-2 mt-2">
          {notification.onCancel && (
            <button className="btn-text" onClick={notification.onCancel.onClick}>
              {notification.onCancel.label}
            </button>
          )}
          {notification.onConfirm && (
            <button className="btn-success" onClick={notification.onConfirm.onClick}>
              {notification.isLoading && <Spinner className="mr-2" />}
              {notification.onConfirm.label}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default function NotificationsContainer() {
  const { notifications, deleteNotification } = useNotifications()

  if (notifications.length === 0) return null

  return (
    <div className={'fixed bottom-0 right-0 m-4 z-10'}>
      {notifications.map(n => (
        <Notification
          notification={n}
          key={n.id}
          onClose={() => {
            deleteNotification(n.id)
            n.onClose?.()
          }}
        />
      ))}
    </div>
  )
}
