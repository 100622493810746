import type { JWTContent } from '@apb/shared/JWTContent'
import { decode } from 'jsonwebtoken'

export function saveToken(token: string, remember = true): void {
  const storage = remember ? window.localStorage : window.sessionStorage

  storage.setItem('token', token)
}

export function getToken(): string | null {
  if (!window) return null
  return window.localStorage.getItem('token') || window.sessionStorage.getItem('token')
}

function isJWTContent(data: any): data is JWTContent {
  if (typeof data !== 'object' || data === null) return false

  if (typeof data.id !== 'number') return false
  if (data.nome !== null && typeof data.nome !== 'string') return false
  if (data.cognome !== null && typeof data.cognome !== 'string') return false
  return true
}
export function parseToken(token: string): JWTContent | null {
  const data = decode(token, { json: true })

  if (isJWTContent(data)) {
    return data
  }

  return null
}

export function clearToken(): void {
  window.localStorage.removeItem('token')
  window.sessionStorage.removeItem('token')
}
