import type { AppRouter } from '@apb/api-server/router'
import { captureException } from '@sentry/nextjs'
import { createTRPCProxyClient, CreateTRPCClientOptions, loggerLink, httpLink, TRPCClientError } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import superjson from 'superjson'

import { baseApiUrl, isDev } from './config'
import { getToken } from './services/auth'

export const trpc = createTRPCReact<AppRouter>()

const opts: CreateTRPCClientOptions<AppRouter> = {
  links: [
    loggerLink({
      enabled: () => true,
      console: {
        log: isDev
          ? console.log
          : () => {
              // void
            },
        error: (...args) => {
          console.error(...args)
          if (!isDev)
            captureException(new Error(args[0]), {
              extra: { errorSource: 'trpc logger link', otherArgs: args.slice(1) }
            })
        }
      }
    }),
    httpLink({
      url: `${baseApiUrl}/trpc`,
      // You can pass any HTTP headers you wish here
      headers: () => {
        const token = getToken()
        return {
          Authorization: token !== null ? `bearer ${token}` : ''
        }
      },
      fetch: (url, options) => {
        return fetch(url, { ...options, credentials: 'include' })
      }
    })
  ],
  transformer: superjson
}

export const createTRPCReactClient = () => trpc.createClient(opts)

export const trpcClient = createTRPCProxyClient<AppRouter>(opts)

export type TTRPCError = TRPCClientError<AppRouter>
