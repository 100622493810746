import type { Utente } from '@apb/database/schema'
import type { JWTContent } from '@apb/shared/JWTContent'
import { atom, useRecoilValue } from 'recoil'

export type AuthState =
  | { user: JWTContent; role: Utente['role']; path: string; state: 'loggedIn' }
  | { state: 'loggedOut' }
  | { state: 'loading' }

const authAtom = atom<AuthState>({ key: 'auth', default: { state: 'loading' } })

export default authAtom

export function useAuthState(): AuthState {
  return useRecoilValue(authAtom)
}
